/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Health } from '~/presentation/base/icons';
import { StepMagic, Step } from '~/presentation/components/steps';
import { Button } from '~/presentation/components/UI/buttons';
import Prescription, { ownProps as PrescriptionProps } from './Prescription';
import { Signature } from '~/presentation/components/signature';
import {
  Body,
  Container,
  Content,
  Header,
  ContainerButton,
} from './styles/StyledApac';

import GeneralData, { ownProps as GeneralDataProps } from './GeneralData';

import { CreateClinicalDocs as ReduxCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/CreateClinicalDocs';
import { CreateClinicalDocs as RemoteCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote/CreateClinicalDocs';

import { ConnectComponent } from './mapper/MapperSimplePrescription';

import { iValidation, iPrescription } from './interface';
import { makeReduxCreateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateClinicalDocsFactory';
import { iStore } from '~/domain/interfaces/models';
import { translator } from '../i18n';
import { makeRemoteGetClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsFactory';
import { History } from '~/routes';
import PatientData from '../PatientDataClinicalDocuments';
import { makeRemoteCreateForeignClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateForeignClinicalDocsFactory';
import { CreateForeignClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import Address from '../register/Address';
import { makeRemoteGetAllDocType } from '~/main/factories/usecases/docType/GetAllDocType';

export interface Props {
  ambulatorial: RemoteCreateClinicalDocs | ReduxCreateClinicalDocs;
}

export type ownProps = Props & GeneralDataProps;

interface iStateParams {
  appointmentId: string;
  professional: string;
  consultant: string;
}

const SimplePrescription: React.FC<ownProps> = (props): JSX.Element => {
  const [crement, setCrement] = useState<number>(1);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const stateParams = useLocation<iStateParams>().state;

  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const { auth } = useSelector((store: iStore) => store);
  const { role, orgUnitId, orgId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const specialty =
    role === 'PRO'
      ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
          ?.professions.specialties[0].id
      : -1;

  const [ambulatorial, setAmbulatorial] = useState<iValidation>({
    professional:
      stateParams && stateParams.professional
        ? Number(stateParams.professional)
        : -1,
    specialty,
  });

  function handleBar(number: number) {
    if (crement + number > 5 || crement + number < 1) {
      setCrement(crement);
      createAmbulatorial();
    } else {
      setCrement(crement + number);
    }
  }

  let dataToSend: RemoteCreateClinicalDocs.Params = {};

  const createAmbulatorial = () => {
    // VINCULADO A UM ATENDIMENTO
    if (stateParams && stateParams.appointmentId !== undefined) {
      dataToSend = {
        healthUnit: ambulatorial.healthUnit,
        appointment: Number(stateParams.appointmentId),
        type: 7,
        patient:
          ambulatorial.situation === 'NOT-REGISTERED'
            ? undefined
            : ambulatorial?.patient?.id,
        patientExternal:
          ambulatorial.situation === 'NOT-REGISTERED'
            ? {
                name: ambulatorial?.patient?.fullname ?? '',
                birthDate: ambulatorial?.patient?.birthDate ?? '',
                email: ambulatorial?.patient?.email ?? '',
                motherName: ambulatorial?.patient?.motherName ?? '',
                phone: ambulatorial?.patient?.phone ?? '',
                sex: ambulatorial?.patient?.gender ?? 'M',
                medicalRecord: ambulatorial?.patient?.medicalRecord ?? '',
                address: {
                  city: ambulatorial?.address?.city ?? '',
                  cityCodeIBGE: ambulatorial?.address?.cityCodeIBGE ?? '',
                  neighborhood: ambulatorial?.address?.neighborhood ?? '',
                  number: ambulatorial?.address?.number ?? '',
                  state: ambulatorial?.address?.uf ?? '',
                  street: ambulatorial?.address?.street ?? '',
                  zipCode: ambulatorial?.address?.zipcode ?? '',
                },
              }
            : undefined,
        content: {
          primaryProcedure: {
            code: ambulatorial.contentRaw?.primaryProcedure?.code ?? '',
            name: ambulatorial.contentRaw?.primaryProcedure?.name ?? '',
            quantity: ambulatorial.contentRaw?.primaryProcedure?.quantity ?? 0,
          },
          secundaryProcedures: [
            {
              code: ambulatorial.contentRaw?.secundaryProcedures1?.code ?? '',
              name: ambulatorial.contentRaw?.secundaryProcedures1?.name ?? '',
              quantity:
                ambulatorial.contentRaw?.secundaryProcedures1?.quantity ?? 0,
            },
            {
              code: ambulatorial.contentRaw?.secundaryProcedures2?.code ?? '',
              name: ambulatorial.contentRaw?.secundaryProcedures2?.name ?? '',
              quantity:
                ambulatorial.contentRaw?.secundaryProcedures2?.quantity ?? 0,
            },
            {
              code: ambulatorial.contentRaw?.secundaryProcedures3?.code ?? '',
              name: ambulatorial.contentRaw?.secundaryProcedures3?.name ?? '',
              quantity:
                ambulatorial.contentRaw?.secundaryProcedures3?.quantity ?? 0,
            },
            {
              code: ambulatorial.contentRaw?.secundaryProcedures4?.code ?? '',
              name: ambulatorial.contentRaw?.secundaryProcedures4?.name ?? '',
              quantity:
                ambulatorial.contentRaw?.secundaryProcedures4?.quantity ?? 0,
            },
            {
              code: ambulatorial.contentRaw?.secundaryProcedures5?.code ?? '',
              name: ambulatorial.contentRaw?.secundaryProcedures5?.name ?? '',
              quantity:
                ambulatorial.contentRaw?.secundaryProcedures5?.quantity ?? 0,
            },
          ],
          diagnosis: {
            description: ambulatorial.contentRaw?.diagnosis?.description ?? '',
            primaryCID: ambulatorial.contentRaw?.diagnosis?.primaryCID ?? '',
            secundaryCID:
              ambulatorial.contentRaw?.diagnosis?.secundaryCID ?? '',
            associatedCID:
              ambulatorial.contentRaw?.diagnosis?.associatedCID ?? '',
          },
        },
        signatureSource: ambulatorial?.signatureSource ?? undefined,
        certificate:
          ambulatorial?.signatureSource === 'VIDAAS'
            ? undefined
            : ambulatorial.certificate,
        certificateId:
          ambulatorial?.signatureSource === 'VIDAAS'
            ? undefined
            : ambulatorial.certificateId,
      };

      try {
        if (ambulatorial.signatureSource === 'VIDAAS') {
          if (ambulatorial.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 7,
                  certificate:
                    ambulatorial?.signatureSource === 'VIDAAS'
                      ? undefined
                      : ambulatorial.signatureSource,
                  content: {
                    primaryProcedure: {
                      code:
                        ambulatorial.contentRaw?.primaryProcedure?.code ?? '',
                      name:
                        ambulatorial.contentRaw?.primaryProcedure?.name ?? '',
                      quantity:
                        ambulatorial.contentRaw?.primaryProcedure?.quantity ??
                        0,
                    },
                    secundaryProcedures: [
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures1?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures1?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures1
                            ?.quantity ?? 0,
                      },
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures2?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures2?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures2
                            ?.quantity ?? 0,
                      },
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures3?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures3?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures3
                            ?.quantity ?? 0,
                      },
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures4?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures4?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures4
                            ?.quantity ?? 0,
                      },
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures5?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures5?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures5
                            ?.quantity ?? 0,
                      },
                    ],
                    diagnosis: {
                      description:
                        ambulatorial.contentRaw?.diagnosis?.description ?? '',
                      primaryCID:
                        ambulatorial.contentRaw?.diagnosis?.primaryCID ?? '',
                      secundaryCID:
                        ambulatorial.contentRaw?.diagnosis?.secundaryCID ?? '',
                      associatedCID:
                        ambulatorial.contentRaw?.diagnosis?.associatedCID ?? '',
                    },
                  },
                  patient: {
                    age: Number(ambulatorial?.patient?.age),
                    fullname: ambulatorial?.patient?.fullname!,
                    gender:
                      (ambulatorial?.patient
                        ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender']) ??
                      'MALE',
                  },
                  signatureSource: ambulatorial.signatureSource ?? undefined,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: ambulatorial.professional,
                  specialty,
                },
              })
              .catch(e => {
                console.log('##error: ', e);
              })
              .then(response => {
                console.log('##response: ', response);
                makeRemoteGetClinicalDocs()
                  .get({ docId: Number(response?.id) })
                  .then(res => {
                    const url = res?.dataToSign;
                    localStorage.setItem('clinicalDocId', String(response?.id));
                    // window.open(url, '_blank');
                    window.location.href = url;
                  })

                  .catch(e => console.log(e));
              });
            return;
          }
          makeReduxCreateClinicalDocs().create({
            ...dataToSend,
            signDocWithVidaas: (id: number) => {
              makeRemoteGetClinicalDocs()
                .get({ docId: id })
                .then(res => {
                  const url = res?.dataToSign;
                  localStorage.setItem('clinicalDocId', String(id));
                  // window.open(url, '_blank');
                  window.location.href = url;
                })

                .catch(e => console.log(e));
            },
          });
        } else {
          makeReduxCreateClinicalDocs().create(dataToSend);
        }
      } catch (e) {
        console.error(e);
      }
      // NÃO VINCULADO A UM ATENDIMENTO
    } else {
      dataToSend = {
        org: orgId,
        orgUnit: orgUnitId,
        healthUnit: ambulatorial.healthUnit,
        patient:
          ambulatorial.situation === 'NOT-REGISTERED'
            ? undefined
            : ambulatorial?.patient?.id,
        patientExternal:
          ambulatorial.situation === 'NOT-REGISTERED'
            ? {
                name: ambulatorial?.patient?.fullname ?? '',
                birthDate: ambulatorial?.patient?.birthDate ?? '',
                email: ambulatorial?.patient?.email ?? '',
                motherName: ambulatorial?.patient?.motherName ?? '',
                phone: ambulatorial?.patient?.phone ?? '',
                sex: ambulatorial?.patient?.gender ?? 'M',
                medicalRecord: ambulatorial?.patient?.medicalRecord ?? '',
                address: {
                  city: ambulatorial?.address?.city ?? '',
                  cityCodeIBGE: ambulatorial?.address?.cityCodeIBGE ?? '',
                  neighborhood: ambulatorial?.address?.neighborhood ?? '',
                  number: ambulatorial?.address?.number ?? '',
                  state: ambulatorial?.address?.uf ?? '',
                  street: ambulatorial?.address?.street ?? '',
                  zipCode: ambulatorial?.address?.zipcode ?? '',
                },
              }
            : undefined,
        professional: ambulatorial.professional,
        type: 7,
        content: {
          primaryProcedure: {
            code: ambulatorial.contentRaw?.primaryProcedure?.code ?? '',
            name: ambulatorial.contentRaw?.primaryProcedure?.name ?? '',
            quantity: ambulatorial.contentRaw?.primaryProcedure?.quantity ?? 0,
          },
          secundaryProcedures: [
            {
              code: ambulatorial.contentRaw?.secundaryProcedures1?.code ?? '',
              name: ambulatorial.contentRaw?.secundaryProcedures1?.name ?? '',
              quantity:
                ambulatorial.contentRaw?.secundaryProcedures1?.quantity ?? 0,
            },
            {
              code: ambulatorial.contentRaw?.secundaryProcedures2?.code ?? '',
              name: ambulatorial.contentRaw?.secundaryProcedures2?.name ?? '',
              quantity:
                ambulatorial.contentRaw?.secundaryProcedures2?.quantity ?? 0,
            },
            {
              code: ambulatorial.contentRaw?.secundaryProcedures3?.code ?? '',
              name: ambulatorial.contentRaw?.secundaryProcedures3?.name ?? '',
              quantity:
                ambulatorial.contentRaw?.secundaryProcedures3?.quantity ?? 0,
            },
            {
              code: ambulatorial.contentRaw?.secundaryProcedures4?.code ?? '',
              name: ambulatorial.contentRaw?.secundaryProcedures4?.name ?? '',
              quantity:
                ambulatorial.contentRaw?.secundaryProcedures4?.quantity ?? 0,
            },
            {
              code: ambulatorial.contentRaw?.secundaryProcedures5?.code ?? '',
              name: ambulatorial.contentRaw?.secundaryProcedures5?.name ?? '',
              quantity:
                ambulatorial.contentRaw?.secundaryProcedures5?.quantity ?? 0,
            },
          ],
          diagnosis: {
            description: ambulatorial.contentRaw?.diagnosis?.description ?? '',
            primaryCID: ambulatorial.contentRaw?.diagnosis?.primaryCID ?? '',
            secundaryCID:
              ambulatorial.contentRaw?.diagnosis?.secundaryCID ?? '',
            associatedCID:
              ambulatorial.contentRaw?.diagnosis?.associatedCID ?? '',
          },
        },
        certificate:
          ambulatorial?.signatureSource === 'VIDAAS'
            ? undefined
            : ambulatorial.certificate,
        certificateId:
          ambulatorial?.signatureSource === 'VIDAAS'
            ? undefined
            : ambulatorial.certificateId,
        specialty,
        signatureSource: ambulatorial.signatureSource ?? undefined,
      };

      try {
        if (ambulatorial?.signatureSource === 'VIDAAS') {
          if (ambulatorial.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 7,
                  certificate:
                    ambulatorial?.signatureSource === 'VIDAAS'
                      ? undefined
                      : ambulatorial.signatureSource,
                  content: {
                    primaryProcedure: {
                      code:
                        ambulatorial.contentRaw?.primaryProcedure?.code ?? '',
                      name:
                        ambulatorial.contentRaw?.primaryProcedure?.name ?? '',
                      quantity:
                        ambulatorial.contentRaw?.primaryProcedure?.quantity ??
                        0,
                    },
                    secundaryProcedures: [
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures1?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures1?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures1
                            ?.quantity ?? 0,
                      },
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures2?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures2?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures2
                            ?.quantity ?? 0,
                      },
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures3?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures3?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures3
                            ?.quantity ?? 0,
                      },
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures4?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures4?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures4
                            ?.quantity ?? 0,
                      },
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures5?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures5?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures5
                            ?.quantity ?? 0,
                      },
                    ],
                    diagnosis: {
                      description:
                        ambulatorial.contentRaw?.diagnosis?.description ?? '',
                      primaryCID:
                        ambulatorial.contentRaw?.diagnosis?.primaryCID ?? '',
                      secundaryCID:
                        ambulatorial.contentRaw?.diagnosis?.secundaryCID ?? '',
                      associatedCID:
                        ambulatorial.contentRaw?.diagnosis?.associatedCID ?? '',
                    },
                  },
                  patient: {
                    age: Number(ambulatorial?.patient?.age),
                    fullname: ambulatorial?.patient?.fullname!,
                    gender:
                      (ambulatorial?.patient
                        ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender']) ??
                      'MALE',
                  },
                  signatureSource: ambulatorial.signatureSource ?? undefined,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: ambulatorial.professional,
                  specialty,
                },
              })
              .catch((e: any) => {
                console.log('##error: ', e);
              })
              .then(response => {
                console.log('##response: ', response);
                makeRemoteGetClinicalDocs()
                  .get({ docId: Number(response?.id) })
                  .then(res => {
                    const url = res?.dataToSign;
                    localStorage.setItem('clinicalDocId', String(response?.id));
                    // window.open(url, '_blank');
                    window.location.href = url;
                  })

                  .catch(e => console.log(e));
              });
            return;
          }
          makeReduxCreateClinicalDocs().create({
            ...dataToSend,
            signDocWithVidaas: (id: number) => {
              makeRemoteGetClinicalDocs()
                .get({ docId: id })
                .then(res => {
                  const url = res?.dataToSign;
                  localStorage.setItem('clinicalDocId', String(id));
                  // window.open(url, '_blank');
                  window.location.href = url;
                })

                .catch(e => console.log(e));
            },
          });
        } else {
          if (ambulatorial.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 7,
                  certificate:
                    ambulatorial?.signatureSource === 'VIDAAS'
                      ? undefined
                      : ambulatorial.signatureSource,
                  content: {
                    primaryProcedure: {
                      code:
                        ambulatorial.contentRaw?.primaryProcedure?.code ?? '',
                      name:
                        ambulatorial.contentRaw?.primaryProcedure?.name ?? '',
                      quantity:
                        ambulatorial.contentRaw?.primaryProcedure?.quantity ??
                        0,
                    },
                    secundaryProcedures: [
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures1?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures1?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures1
                            ?.quantity ?? 0,
                      },
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures2?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures2?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures2
                            ?.quantity ?? 0,
                      },
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures3?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures3?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures3
                            ?.quantity ?? 0,
                      },
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures4?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures4?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures4
                            ?.quantity ?? 0,
                      },
                      {
                        code:
                          ambulatorial.contentRaw?.secundaryProcedures5?.code ??
                          '',
                        name:
                          ambulatorial.contentRaw?.secundaryProcedures5?.name ??
                          '',
                        quantity:
                          ambulatorial.contentRaw?.secundaryProcedures5
                            ?.quantity ?? 0,
                      },
                    ],
                    diagnosis: {
                      description:
                        ambulatorial.contentRaw?.diagnosis?.description ?? '',
                      primaryCID:
                        ambulatorial.contentRaw?.diagnosis?.primaryCID ?? '',
                      secundaryCID:
                        ambulatorial.contentRaw?.diagnosis?.secundaryCID ?? '',
                      associatedCID:
                        ambulatorial.contentRaw?.diagnosis?.associatedCID ?? '',
                    },
                  },
                  patient: {
                    age: Number(ambulatorial?.patient?.age),
                    fullname: ambulatorial?.patient?.fullname!,
                    gender:
                      (ambulatorial?.patient
                        ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender']) ??
                      'MALE',
                  },
                  signatureSource: undefined,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: ambulatorial.professional,
                  specialty,
                },
              })
              .then(() => {
                History.goBack();
              })
              .catch((err: any) => {
                console.error(err);
              });

            return;
          }

          makeReduxCreateClinicalDocs().create(dataToSend);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  function next(dados: iValidation) {
    setAmbulatorial({ ...ambulatorial, ...dados });
    handleBar(1);
  }

  function back(dados: iValidation) {
    setAmbulatorial({ ...ambulatorial, ...dados });
    handleBar(-1);
  }

  return (
    <Container>
      <Content>
        <Health />
        <h1>{`${translator('Solicitação/Autorização de Procedimento Ambulatorial')}`}</h1>
      </Content>
      <Header>
        <StepMagic current={crement}>
          <Step title={`${translator('Informações gerais')}`} />
          <Step title={`${translator('Dados do paciente')}`} />
          <Step title={`${translator('Endereço do paciente')}`} />
          <Step title={`${translator('Procedimentos')}`} />
          <Step title={`${translator('Assinatura do documento')}`} />
        </StepMagic>
      </Header>
      <Body>
        {crement === 1 && (
          <GeneralData
            professional={props.professional}
            consultant={props.consultant}
            next={next}
            state={ambulatorial}
          />
        )}
        {crement === 2 && (
          <PatientData
            state={ambulatorial}
            consultant={props.consultant}
            next={next}
            back={back}
            apac
          />
        )}
        {crement === 3 && (
          <Address
            state={ambulatorial}
            next={next}
            back={back}
            ibgeCode
            cancel="/clinicaldoc"
          />
        )}
        {crement === 4 && (
          <Prescription next={next} back={back} state={ambulatorial} />
        )}
        {crement === 5 && (
          <Signature
            changeSelected={(e: {
              certificateId: string;
              certificate: string;
              signatureSource?: string;
            }) =>
              setAmbulatorial({
                ...ambulatorial,
                certificate: e.certificate,
                certificateId: e.certificateId,
                signatureSource: e.signatureSource,
              })
            }
            changeDisabled={(state: boolean) => setDisableButton(state)}
            onSignSubmit={createAmbulatorial}
          />
        )}
      </Body>
      {crement === 5 ? (
        <ContainerButton>
          <Button
            id="button_back"
            rounded
            variant="secundary"
            onClick={() => handleBar(-1)}
            size="small"
          >
            {`${translator('Voltar')}`}
          </Button>
          <Button
            id="button_conclude"
            rounded
            size="small"
            onClick={createAmbulatorial}
            disabled={loading || disableButton}
          >
            {`${translator('Concluir')}`}
          </Button>
        </ContainerButton>
      ) : null}
    </Container>
  );
};

export default ConnectComponent(SimplePrescription);
