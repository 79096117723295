import { array, number, object, string } from 'zod';

export const schemaProcedure = object({
  primaryProcedure: object({
    code: string().optional(),
    name: string().optional(),
    quantity: number().optional(),
  }),
  secundaryProcedures1: object({
    code: string().optional(),
    name: string().optional(),
    quantity: number().optional(),
  }),
  secundaryProcedures2: object({
    code: string().optional(),
    name: string().optional(),
    quantity: number().optional(),
  }),
  secundaryProcedures3: object({
    code: string().optional(),
    name: string().optional(),
    quantity: number().optional(),
  }),
  secundaryProcedures4: object({
    code: string().optional(),
    name: string().optional(),
    quantity: number().optional(),
  }),
  secundaryProcedures5: object({
    code: string().optional(),
    name: string().optional(),
    quantity: number().optional(),
  }),
  diagnosis: object({
    description: string().optional(),
    primaryCID: string().optional(),
    secundaryCID: string().optional(),
    associatedCID: string().optional(),
  }),
});

export const schemaPrescription = object({
  name: string().nonempty({ message: 'Insira o nome da droga' }),
  dosage: string().nonempty({ message: 'Insira a dosagem' }),
  posology: string().nonempty({ message: 'Insira a posologia da medicação' }),
});

const schema = object({
  drugs: array(
    object({
      name: string().nonempty(),
      dosage: string().nonempty(),
      posology: string().nonempty(),
    }),
  ),
});

export default schema;
